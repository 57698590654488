import { Component, OnInit } from '@angular/core';

import { OktaAuthService } from '@okta/okta-angular';
import { HttpClient } from '@angular/common/http';

import sampleConfig from '../app.config';


@Component({
  selector: 'app-server-profile',
  templateUrl: './server-profile.component.html',
  styleUrls: ['./server-profile.component.css']
})
export class ServerProfileComponent implements OnInit {
  failed: Boolean;
  jwtToken: any;

  constructor(public oktaAuth: OktaAuthService, private http: HttpClient) { }

  async ngOnInit() {
    const accessToken = await this.oktaAuth.getAccessToken();
    this.http.get(sampleConfig.resourceServer.profileUri, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      }
    }).subscribe((data: any) => {
      this.jwtToken = data;
    }, (err) => {
      console.error(err);
      this.failed = true;
    });
  }

}
