import { environment } from '../environments/environment';

const { CLIENT_ID, ISSUER, OKTA_TESTING_DISABLEHTTPSCHECK } = process.env;

export default {
  oidc: {
    clientId: '0oaatdgy4nfjzzhxU4x6',
    xxissuer: 'https://dev-423711.okta.com/oauth2/default',
    issuer: 'https://idm.helg.com/oauth2/default',
    redirectUri: 'https://spa.app.helg.com/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    testing: {
      disableHttpsCheck: `${OKTA_TESTING_DISABLEHTTPSCHECK}`
    }
  },
  resourceServer: {
    messagesUrl: 'https://spa.app.helg.com/api/messages',
    profileUri: 'https://spa.app.helg.com/api/profile',
  },
};
